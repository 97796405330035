export default {
  GET(state, data) {
    state.warehouse = { ...data }
  },
  LIST(state, data) {
    state.warehouses = data
  },
  SET_FORM(state, data) {
    state.warehouseForm = { ...data }
  },
  SET_LOCATION_STATE(state, booleanData) {
    state.isWarehouseLocationsChanging = booleanData
  },
  SET_LOCATION_TYPING_STATE(state, booleanData) {
    state.isWarehouseLocationsTyping = booleanData
  },
  SET_LOCATION_INPUT_CHANGING_STATE(state, booleanData) {
    state.isWarehouseLocationsInputChanging = booleanData
  },
}

<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import warehouseModule from '@/store/settings/warehouse'

export default {
  name: 'Address',
  created() {
    this.$emit('updateMenu', 'settings-fulfillment-warehouses')
  },
  setup() {
    const MODULE_NAME = 'warehouse'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, warehouseModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>

<style scoped>

</style>

import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'settings/warehouses'
const Actions = crudActions(endpoint)

const updateWarehouse = (ctx, data) => axios.post(`/${endpoint}/${data.get('id')}?_method=PUT`, data)
const createWarehouse = (ctx, data) => axios.post(`/${endpoint}`, data)

export default {
  ...Actions,
  updateWarehouse,
  createWarehouse,
}
